html {
  --commonBorderColor: #C7CFD2;
  --focusBorderColor: #4A575F;
  --tagsBg: #D3E8E5;
  --textColor: #58585A;
}

/* ==== TagsInput === */
.react-tagsinput {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--commonBorderColor);
}

.react-tagsinput--focused {
  border-bottom: 2px solid var(--focusBorderColor) !important;
}

.react-tagsinput-input {
  width: 100% !important;
  color: var(--textColor) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: 'Lato', sans-serif !important;
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 5px !important;
}

.react-tagsinput-input::placeholder {
  color: var(--textColor) !important;
  font-family: 'Lato', sans-serif !important;
  line-height: '140%' !important;
  opacity: 0.6 !important;
}

.react-tagsinput-tag {
  background-color: var(--tagsBg) !important;
  border: 1px solid var(--tagsBg) !important;
  color: var(--textColor) !important;
}
