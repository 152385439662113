body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* IE and Edge supports */
/* firefox support */
* {
  -ms-overflow-style: none;
  /* scrollbar-width: none; */
}

/* Chrome, Safari and Opera support */
/* ::-webkit-scrollbar {
  display: none;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

* {
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  max-width: 5px;
  max-height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 50px;
  background-clip: padding-box;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
