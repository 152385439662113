h1,
p {
  font-family: Lato;
}

.gm-style .gm-style-iw-d {
  overflow: hidden;
}

.gm-style .gm-style-iw-c {
  padding-right: 0;
  padding-bottom: 0px !important;
  max-width: 500px !important;
  max-height: 326px !important;
  min-width: 0px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: #dd9191;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.gm-style .gm-style-iw-t::after {
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 1) 51%,
    rgba(0, 0, 0, 1) 100%
  );
  box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 15px;
}
.gm-style .gm-style-iw-d {
  display: contents;
}

.rdrMonths {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rdrMonth {
  width: 27.667em;
}

@media screen and (max-width: 600px) {
  .rdrMonth {
    width: 270px;
  }
}

.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  width: 100%;
}

.rdrDateRangeWrapper {
  user-select: none;
  width: 100%;
}
.rdrDateDisplayItem {
  background: #39B077;
  /* color: #FFFFFF; */

} 

.rdrDateDisplayItem input, .rdrDateDisplayItem input::-webkit-input-placeholder{
  color: #FFFFFF;
}

.rdrDateDisplayItemActive {
  /* border-color: #FFFFFF; */
  box-shadow: 0 0 3px;

  /* background: #39B077; */
}
